.evp-header {
  padding-top: 4.5rem;

  @media (min-height: 1100px) {
    padding-top: 15%;
  }
}

.evp-profile {
  width: 31rem;
}
