.evp-header {
  padding-top: 4.5rem;
}

@media (height >= 1100px) {
  .evp-header {
    padding-top: 15%;
  }
}

.evp-profile {
  width: 31rem;
}

.evp-exp__bottom {
  height: 100%;
}

.evp-exp__grid {
  height: calc(100% - 4rem);
}

.evp-exp_hidden {
  visibility: hidden;
  transform: translateY(-500%);
}

.evp-exp__desc {
  text-align: right;
}

.evp-exp__desc span {
  font-size: .9rem;
  display: block;
}

.evp-exp__grid {
  border-width: 1rem 0;
  transition: all .2s;
}

.evp-exp__grid_pets {
  transform: translateY(100%);
}

.evp-exp__col_socialbet .evp-exp__box, .evp-exp__col_ignet .evp-exp__box, .evp-exp__col_spaze .evp-exp__box, .evp-exp__col_sehack .evp-exp__box {
  margin-top: .5rem;
  position: relative;
}

.evp-exp__col_spaze picture {
  margin: 0 0 0 auto;
}

.evp-exp__col_sehack picture {
  margin: 0 auto 0 0;
}

.evp-exp__col_spaze, .evp-exp__col_sehack {
  margin-right: 0;
}

.evp-exp__col_spaze picture, .evp-exp__col_sehack picture {
  width: 50%;
}

.evp-exp__col_spaze a .img-thumbnail, .evp-exp__col_sehack a .img-thumbnail {
  height: 85% !important;
}

.evp-h-huumun .evp-exp__col_huumun, .evp-h-smarteka .evp-exp__col_smarteka, .evp-h-socialbet .evp-exp__col_socialbet, .evp-h-ignet .evp-exp__col_ignet, .evp-h-embed-mixcloud-advanced .evp-exp__col_embed-mixcloud-advanced, .evp-h-iframe-new-tab .evp-exp__col_iframe-new-tab, .evp-h-spaze .evp-exp__col_spaze, .evp-h-sehack .evp-exp__col_sehack {
  transform: translateY(500%);
}

.evp-exp__col + .evp-exp_hidded {
  visibility: visible;
}

.evp-exp__box {
  padding-bottom: 50% !important;
}

.evp-exp__box .img-thumbnail {
  opacity: .75;
  filter: grayscale(50%);
}

.evp-exp__box-desc {
  white-space: normal;
  box-shadow: none;
  background: none;
  bottom: 0;
}

.evp-exp__box-title span {
  background: radial-gradient(at 50% 30%, #1b5848 0%, #195042 51%, #144035 100%);
  width: fit-content;
  margin: .15rem .05rem;
  padding: .25rem .5rem;
  font-size: .85rem;
  display: inline-block;
}

.evp-exp__box-title span:first-child {
  display: block;
}

.evp-exp__box-role, .evp-exp__box-more {
  display: none;
}

.evp-exp__content {
  top: 200%;
  left: 0 !important;
  right: 0 !important;
}

.evp-exp__content__text {
  height: 100%;
}

.evp-exp__content__text span {
  line-height: 1rem;
}

.evp-exp__content-scrollable {
  height: 35%;
}

.evp-exp__content-scrollable-inner {
  line-height: 1.3rem;
}

.evp-exp__content-thumbnail, .evp-exp__content-desc {
  display: block;
}

.evp-h-iframe-new-tab .evp-exp__content--iframe-new-tab {
  left: 36%;
  right: initial;
}

.evp-h-spaze .evp-exp__content--spaze, .evp-h-sehack .evp-exp__content--sehack {
  left: initial;
  right: 32%;
}

.evp-h-huumun .evp-exp__top, .evp-h-smarteka .evp-exp__top, .evp-h-socialbet .evp-exp__top, .evp-h-ignet .evp-exp__top, .evp-h-embed-mixcloud-advanced .evp-exp__top, .evp-h-iframe-new-tab .evp-exp__top, .evp-h-spaze .evp-exp__top, .evp-h-sehack .evp-exp__top {
  height: 0;
  transition: height .2s;
}

.evp-player {
  height: 9rem;
}

.evp-player .container {
  flex-wrap: wrap;
}

.evp-player .evp-break-column {
  background: #111;
  flex-basis: 100%;
  width: 0;
  height: 1px;
  margin: .25rem 0;
}

.evp-player__info {
  max-width: fit-content;
}

.evp-player__toggle {
  top: 1.25rem;
  right: 0;
}

.evp-player__embed {
  visibility: hidden;
  height: 0;
  bottom: 0;
  width: 100% !important;
  transform: translate(0, -9rem) !important;
}

.evp-player_is-expanded .evp-player__embed {
  visibility: visible;
  height: 250px;
  transition: all .2s;
}
/*# sourceMappingURL=index.3547791d.css.map */
